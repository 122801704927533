<style>
.rounded-image-with-stroke {
  border-radius: 10px; /* Adjust the value to change the roundness */
  box-shadow: 0 0 5px rgba(0,0,0,0.3); /* Adjust the values to change the size and color of the stroke */
  width: 150px;
}
</style>

<template>
  <div>
   <!-- Modal picture-->
   <b-modal
  id="modal-picture"
  v-model="pictureModal"
  hide-footer
  centered
>
  <img :src="assetPhoto" style="width: 100%;" alt="Asset Photo" />
  <b-button close @click="pictureModal = false" slot="modal-footer">Close</b-button>
</b-modal>
  <b-card no-body class="mb-0">
        <b-form @submit.prevent="submitDisposal">
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Asset Disposal By Regional Manager">
        <b-row>
          <b-col cols="6">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            </b-col>
          <b-col cols="6" class="d-flex justify-content-end" style="padding-top:100px;">
            <b-dropdown
  text="Download Documents"
  v-if="documents.length > 0"
  v-model="showDropdown"
  class="float-right"
  style="padding-right:20px;"
>
  <!-- Your dropdown content here -->
  <b-dropdown-item
    v-for="document in documents"
    :key="document.document_number"
    @click="downloadAttachment(document)"
  >
    {{ document.document_file_name }}
  </b-dropdown-item>
</b-dropdown>
            <img v-if="assetPhoto != ''" :src="assetPhoto" alt="Asset Photo" class="rounded-image-with-stroke" @click="showModal"/>
          </b-col>
          <b-col cols="12">
            <div class="devider" />
            <b-form-group label="Disposal Reason *" label-cols-md="12">
            <b-form-input v-model="assetDisposalCategory" readonly/>
            </b-form-group>
            <b-form-group label="Disposal Note *" label-cols-md="12">
            <b-form-input v-model="assetDisposalNote" readonly/>
            </b-form-group>
            <b-form-group label="Disposal PIC Name *" label-cols-md="12">
            <b-form-input v-model="assetDisposalPICName" readonly/>
            </b-form-group>
            <hr/>
            <div class="devider" />
        <b-form-group
          label="Item ID "
          label-for="itemId"
          label-cols-md="12"
        >
          <b-form-input
            :value="item.item.item_code"
            placeholder="itemId"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Asset ID "
          label-for="assetId"
          label-cols-md="12"
        >
          <b-form-input
            v-model="item.asset_number"
            placeholder="Asset ID"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Ref ID "
          label-for="refId"
          label-cols-md="12"
        >
          <b-form-input
            v-model="item.asset_ref_id"
            placeholder="Ref ID"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Local ID "
          label-for="localId"
          label-cols-md="12"
        >
          <b-form-input
            v-model="item.asset_local_id"
            placeholder="Local ID"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Asset Group "
          label-for="category"
          label-cols-md="12"
        >

        <b-form-input
            v-model="item.asset_category"
            placeholder="Asset Description"
                  readonly
          />
        </b-form-group>
        <b-form-group
          label="Description "
          label-for="description"
          label-cols-md="12"
        >
          <b-form-input
            v-model="item.asset_description"
            placeholder="Asset Description"
                  readonly
          />
        </b-form-group>
        <b-form-group
          label="Note "
          label-for="note"
          label-cols-md="12"
        >
          <b-form-input
            v-model="item.asset_note"
            placeholder="Asset Note"
                  readonly
          />
        </b-form-group>
        <b-form-group
          label="GPS"
          label-for="gps"
          label-cols-md="12"
        >
          <b-form-input
            v-model="item.asset_gps"
            placeholder="Asset GPS"
                  readonly
          />
        </b-form-group>
        <b-form-group
          label="Date of Acquisition "
          label-cols-md="12"
        >
          <b-form-input
            v-model="item.asset_date_of_acquistion"
                  readonly
          />
        </b-form-group>
        <b-form-group
                label="Acquisition Value"
                label-for="assetAcquisitionValue"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_acquistion_value"
                  placeholder="Asset Acquisition Value"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Depreciation Method "
                label-for="assetDepreciationMethod"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_depreciation_method"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Residual Value "
                label-for="assetResidualValue"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_residual_value"
                  placeholder="Asset Residual Value"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Market Value "
                label-for="assetMarketValue"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_market_value"
                  placeholder="Asset Market Value"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Asset Condition "
                label-for="assetAssetCondition"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_condition"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Asset Address "
                label-for="assetAddress"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_address"
                  placeholder="Asset Address"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Asset Responsible "
                label-for="assetResponsible"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_responsible"
                  placeholder="Asset Responsible"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Serial Number "
                label-for="assetSerialNumber"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_serial_no"
                  placeholder="Asset Serial Number"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Warranty "
                label-for="assetWarranty"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="item.asset_warranty"
                  placeholder="Asset Warranty"
                  readonly
                />
              </b-form-group>
          </b-col>
        </b-row>
          <!-- submit button -->
          <b-button type="submit" variant="primary" :disabled="submitButtonDisabled">
            Submit
          </b-button>
      </b-card>
    </b-col>
  </b-row>
  </b-form>
  </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormFile,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { ref } from "@vue/composition-api"

const VersionCheck = ""
const items = []
const item = ref()
const chosenItem = ref(3)
const chosens = []
const statusItems = []
const attachments = []
const documents = []
const assetTo = ""
const assetCc = ""
const assetReason = ""
const assetQtyRequested = 0
const assetStatus = ""
const assetId = ""
const assetNumber = ""
const assetType = ""
const assetPhoto = ""
const assetDisposalCategory = ""
const assetDisposalNote = ""
const assetDisposalPICName = ""
const materialrequestIsAcceptedBySupervisor = 0
const materialrequestIsAcceptedByWarehouse = 0
const materialrequestIsReceived = 0
const pictureModal = false
const categories = ['Jual', 'Lelang', 'Buang']
const apiUrl = process.env.VUE_APP_API_URL
export default {
  components: {
    BDropdown,
    BFormFile,
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        documents,
        attachments,
        assetDisposalPICName,
        assetDisposalCategory,
        assetDisposalNote,
        categories,
      pictureModal,
      assetPhoto,
      item,
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      assetTo,
      assetCc,
      assetReason,
      assetQtyRequested,
      assetStatus,
      assetId,
      assetNumber,
      materialrequestIsAcceptedBySupervisor,
      materialrequestIsAcceptedByWarehouse,
      materialrequestIsReceived,
      assetType,
      apiUrl,
      submitButtonDisabled: false,
    }
  },
  mounted() {
    this.getDetail()
    this.getDocuments()
  },
  methods: {

    submitDisposal() {
  this.submitButtonDisabled = true
        const body = {
        time: moment(),
        }
    const userToken = this.$cookies.get('userToken')
    const headers = {
        'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }

    axios
      .post(
        `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_DISPOSE_ASSET}regional_manager/${this.assetId}`,
        body,
        { headers },
      )
      .then(response => {
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Asset Disposal Confirm Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'apps-procurement-assets-list' })
        } else {
          this.submitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Asset Disposal Confirm Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Asset Disposal Confirm Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
},
    getDocuments() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ASSET_DOCUMENTS}/${this.item.asset_id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push(elem)
            })
            this.documents = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Documents Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Documents Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
handleFilesUploadPhoto(e) {
      this.attachments = e.target.files || e.dataTransfer.files
    },
    showModal() {
      this.pictureModal = true
    },
    getDetail() {
            const userData = this.$cookies.get('userData')
        this.assetDisposalPICName = userData.user_fullname
      this.item = JSON.parse(localStorage.getItem("assetDetail"))
      this.assetId = this.item.asset_id
      this.assetDisposalCategory = this.item.asset_is_disposed_category
      this.assetDisposalNote = this.item.asset_is_disposed_note
      this.assetDisposalPICName = this.item.asset_is_disposed_pic_name
      this.assetPhoto = `${process.env.VUE_APP_API_URL}upload/${this.item.asset_photo}`
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.value !== val)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    previous() {
      this.$router.push({ name: "apps-procurement-assets-list" })
    },
    getFullUrl(picturePath) {
      return `${this.apiUrl}/upload/${picturePath}`
    },
    capitalizeWords(str) {
      // Split the string into words using spaces as the separator
      const words = str.split(" ")
      // Capitalize the first letter of each word and join them back together
      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      // Join the capitalized words into a single string with spaces
      return capitalizedWords.join(" ")
    },
  },
}
</script>
